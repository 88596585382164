'use client'
import { HTMLMotionProps, motion } from 'framer-motion'
import React, { forwardRef, ReactNode } from 'react'
import Link from 'next/link'
import clsx from 'clsx'
import { SpinnerTailwind } from '../spinner-tailwind'

export type ButtonPlatformProps = HTMLMotionProps<'button'> & {
  white?: boolean
  disabled?: boolean
  href?: string
  full?: boolean
  isLoading?: boolean
  icon?: ReactNode
  className?: string
  children?: ReactNode
}

export const PrimaryButton = forwardRef<HTMLButtonElement, ButtonPlatformProps>(
  function Button(props, ref) {
    const {
      white = false,
      disabled = false,
      full = false,
      isLoading = false,
      icon,
      children,
      href,
      className = '',
      ...btnProps
    } = props

    return (
      <>
        {href ? (
          <Link href={href} passHref>
            <ButtonContent
              ref={ref}
              white={white}
              disabled={disabled}
              full={full}
              isLoading={isLoading}
              icon={icon}
              btnProps={btnProps}
              className={className}>
              {children}
            </ButtonContent>
          </Link>
        ) : (
          <ButtonContent
            ref={ref}
            white={white}
            disabled={disabled}
            full={full}
            isLoading={isLoading}
            icon={icon}
            btnProps={btnProps}
            className={className}>
            {children}
          </ButtonContent>
        )}
      </>
    )
  }
)

const ButtonContent = forwardRef<
  HTMLButtonElement,
  ButtonPlatformProps & {
    btnProps?: Omit<
      ButtonPlatformProps,
      'white' | 'disabled' | 'href' | 'full' | 'isLoading' | 'icon' | 'children'
    >
  }
>(function ButtonContent(
  {
    white = false,
    disabled = false,
    full = false,
    isLoading = false,
    icon,
    children,
    btnProps = {},
    className = '',
  },
  ref
) {
  return (
    <motion.button
      ref={ref}
      whileTap={!disabled ? { scale: 0.9 } : {}}
      {...btnProps}
      className={clsx(
        'rounded-[40px] px-6 text-sm font-semibold',
        className,
        white
          ? [
              'border border-platform-blue-300',
              disabled
                ? 'cursor-not-allowed bg-platform-blue-100 text-platform-black-300'
                : 'text-platform-black-700 active:text-platform-black-950',
            ]
          : [
              '',
              disabled
                ? 'cursor-not-allowed bg-platform-blue-700 text-platform-black-200'
                : 'bg-platform-blue-900 text-platform-blue-50 hover:bg-platform-blue-700 active:bg-platform-blue-950',
            ],
        full ? 'w-full' : 'max-w-full',
        'h-auto min-h-[48px] min-w-[120px]'
      )}>
      <div className="relative flex items-center justify-center gap-2">
        {icon && <span className="flex items-center">{icon}</span>}
        {isLoading ? (
          <>
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
              <SpinnerTailwind
                colorClass={
                  white ? 'bg-platform-black-950' : 'bg-platform-blue-50'
                }
              />
            </div>
            <span className="invisible">{children as ReactNode}</span>
          </>
        ) : (
          <span className="break-words py-1 text-center">
            {children as ReactNode}
          </span>
        )}
      </div>
    </motion.button>
  )
})
