import { ModalCentered } from '@repo/ui/components/modal/modal-centered'
import React from 'react'
import { useLostConnectionStore } from '@repo/ui/components/badges/lost-connection-store'

type LostConnectionProps = {}

export default function LostConnectionModal({}: LostConnectionProps) {
  const { setOpen, isOpen } = useLostConnectionStore()

  return (
    <ModalCentered
      isOpen={isOpen}
      onOpenChange={setOpen}
      mainButton={{
        text: 'CERRAR',
        onClick: () => {
          setOpen(false)
        },
      }}
      title={'¡Uy! Algo ha fallado en la conexión'}>
      <p
        className={
          'text-center text-base font-normal leading-normal text-[#475466]'
        }>
        No te preocupes, tu progreso en el examen se ha guardado
        automáticamente. Podrás continuar desde donde lo dejaste una vez que se
        restablezca la conexión.
      </p>
    </ModalCentered>
  )
}
