'use client'

import { CloseCross } from '../../icons'
import React, { PropsWithChildren, useEffect, useRef } from 'react'
import { useBreakPoint } from '../../hooks'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { PrimaryButton } from '../platform-buttons/primary-button'

export type ModalCenteredProps = PropsWithChildren & {
  title: string
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  mainButton?: {
    text: string
    href?: string | undefined
    onClick: () => void
  }
  icon?: React.ReactNode
}

export function ModalCentered({
  title,
  isOpen,
  onOpenChange,
  mainButton,
  children,
  icon,
}: ModalCenteredProps) {
  const isBp1 = useBreakPoint('bp1')
  const modalRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.focus()
    }
  }, [isOpen])

  return (
    <DialogPrimitive.Root open={isOpen} onOpenChange={onOpenChange}>
      <DialogPrimitive.Trigger asChild></DialogPrimitive.Trigger>
      <DialogPrimitive.Portal>
        <DialogPrimitive.Close asChild>
          <DialogPrimitive.Overlay
            style={{
              backgroundColor: '#00000040',
              position: 'fixed',
              inset: '0',
              animation: 'overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1)',
              zIndex: 50,
            }}
          />
        </DialogPrimitive.Close>
        <DialogPrimitive.Content
          ref={modalRef}
          style={{
            backgroundColor: 'white',
            borderRadius: isBp1 ? '0px' : '12px',
            boxShadow:
              'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
            position: 'fixed',
            top: isBp1 ? '0' : '50%',
            left: isBp1 ? '0' : '50%',
            transform: isBp1 ? '' : 'translate(-50%, -50%)',
            width: isBp1 ? '100%' : '90vw',
            height: isBp1 ? '100%' : 'fit-content',
            maxWidth: isBp1 ? '100%' : '450px',
            maxHeight: isBp1 ? '100%' : '85vh',
            padding: '25px',
            animation: 'contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1)',
            zIndex: 51,
          }}>
          <div
            className={
              'my-6 flex flex-col gap-4 leading-5 bp1:h-full bp1:justify-between'
            }>
            <div className={'flex flex-col gap-3'}>
              <div
                className={'fixed right-0 top-0 cursor-pointer p-5'}
                onClick={async () => {
                  onOpenChange(false)
                }}>
                <CloseCross className="fill-black stroke-black" />
              </div>

              {icon}

              <p
                className={
                  'text-center text-xl font-medium leading-[27px] text-[#0f1728]'
                }>
                {title}
              </p>

              {children}
            </div>

            {mainButton && (
              <div className={'grid justify-items-center'}>
                <PrimaryButton
                  full={false}
                  href={mainButton.href || '#'}
                  onClick={mainButton.onClick}>
                  {mainButton.text}
                </PrimaryButton>
              </div>
            )}
          </div>
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  )
}
