'use client'

import { createContext, useContext, useEffect, useState } from 'react'
import {
  getPlatformExam,
  PlatformAndExam,
} from '@repo/platform/academy/get-platform'
import { useSession } from 'next-auth/react'

const AcademyContext = createContext<PlatformAndExam[]>([])

export const useAcademy = () => useContext(AcademyContext)

export type AcademyProviderProps = {
  children: React.ReactNode
}

export function AcademyProvider({ children }: AcademyProviderProps) {
  const { data: session } = useSession()
  const [platformAndExams, setPlatformAndExams] = useState<PlatformAndExam[]>(
    []
  )

  useEffect(() => {
    if (session?.user?.id) {
      getPlatformExam(session?.user?.id || 0).then((platformAndExams) => {
        setPlatformAndExams(platformAndExams)
      })
    }
  }, [session?.user?.id])

  return (
    <AcademyContext.Provider value={platformAndExams || []}>
      {children}
    </AcademyContext.Provider>
  )
}
