'use client'
import { useEffect, useState } from 'react'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { pageViewAnalytics, updateAllConsentToGranted } from './events'
import { CONSENT_STORAGE_KEY } from './constants'

export const useGoogleTagManager = () => {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const [loaded, setLoaded] = useState(false)

  const handleLoadCookiePreferences = () => {
    if (localStorage.getItem(CONSENT_STORAGE_KEY) === '1') {
      updateAllConsentToGranted()
    }
  }

  const completePath = `${pathname}${
    searchParams && searchParams?.toString()
      ? `?${searchParams?.toString()}`
      : ''
  }`

  useEffect(() => {
    handleLoadCookiePreferences()
    if (completePath) {
      pageViewAnalytics(completePath)
    }
     
  }, [completePath])
}
