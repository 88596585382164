'use client'
import { signOut } from 'next-auth/react'
import { useTracking as useTrk } from '@repo/tracking/contexts/tracking'
import { useTracking } from '@/screens/common/use-tracking'
import { TrackEvent } from '@repo/tracking/types/TrackEvents'

export async function doLogout() {
  localStorage.removeItem('survey-storage')
  localStorage.removeItem('vari-store')
  localStorage.removeItem('user-storage')
  localStorage.removeItem('exam-storage-v3')
  localStorage.removeItem('fetch-storage')
  await signOut()
}

export function useLogout() {
  const { addTrack } = useTrk()
  const tracking = useTracking()

  const onLogout = async ({ eventValue }: { eventValue: string }) => {
    addTrack({
      event: TrackEvent.CLOSE_SESSION,
      value: eventValue,
    })
    tracking.trackEvent({
      eventName: 'logout',
      parameters: {
        origin: eventValue,
      },
    })

    await doLogout()
  }

  return { onLogout }
}
