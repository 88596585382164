'use client'
import { createContext, useContext } from 'react'
import { ExamPageConfig } from '@repo/platform/exam/get-page-config'

const ExamConfigContext = createContext<ExamPageConfig>({
  routes: [],
  hasCoursesActive: false,
})

export type ExamProviderProps = {
  children: React.ReactNode
  examPageConfig: ExamPageConfig
}
export const usePageConfig = () => useContext(ExamConfigContext)

export function ExamProvider({ children, examPageConfig }: ExamProviderProps) {
  return (
    <ExamConfigContext.Provider value={examPageConfig}>
      {children}
    </ExamConfigContext.Provider>
  )
}
