'use client'

import { FC } from 'react'
import { twMerge } from 'tailwind-merge'

interface SpinnerProps {
  color?: string
  colorClass?: string
  className?: string
}

export const SpinnerTailwind: FC<SpinnerProps> = ({
  color,
  colorClass = 'bg-black',
  className,
}) => {
  // Determine if we should use inline style or Tailwind class for color
  const useInlineStyle = !!color

  return (
    <div className={twMerge('grid grid-cols-3 gap-2', className)}>
      <div
        className={twMerge(
          'inline-block h-2.5 w-2.5 rounded-full',
          !useInlineStyle && colorClass
        )}
        style={{
          ...(useInlineStyle && { backgroundColor: color }),
          animation: 'bounce-opacity 1.4s infinite ease-in-out both',
          animationDelay: '0s',
        }}
      />
      <div
        className={twMerge(
          'inline-block h-2.5 w-2.5 rounded-full',
          !useInlineStyle && colorClass
        )}
        style={{
          ...(useInlineStyle && { backgroundColor: color }),
          animation: 'bounce-opacity 1.4s infinite ease-in-out both',
          animationDelay: '0.39s',
        }}
      />
      <div
        className={twMerge(
          'inline-block h-2.5 w-2.5 rounded-full',
          !useInlineStyle && colorClass
        )}
        style={{
          ...(useInlineStyle && { backgroundColor: color }),
          animation: 'bounce-opacity 1.4s infinite ease-in-out both',
          animationDelay: '0.69s',
        }}
      />
    </div>
  )
}

// Add this to your global CSS file:
/*
@keyframes bounce-opacity {
  0%, 40%, 100% { 
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
*/
